@import "src/assets/styles/variables";

.container {
	border-radius: 20px;
	border: 1px solid $borderColor;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 63px;
	padding: 14px;
	box-sizing: border-box;
	transition: border-color 250ms linear;

	@media (min-width: $sm) {
		border-radius: 20px;
	}

	&.containerActive {
		border-color: $yellow;
	}

	.info {
		width: 100%;
		transition: max-height 250ms linear, opacity 250ms ease-in;
		height: 18px;

		& > div {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&.infoActive {
			max-height: 18px;
			opacity: 1;
		}

		&.infoInActive {
			max-height: 0;
			opacity: 0;
		}
	}

	.rate {
		letter-spacing: 0.03em;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		color: $yellow;
	}
}