@import "src/assets/styles/variables";

.countryCard {
	border: 1px solid $borderColor;
	padding: 12px 20px;
	border-radius: 10px;
	margin-bottom: 16px;

	.cardHeader {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		margin-bottom: 4px;

		.icon {
			width: 24px;
			height: 24px;
			margin-left: auto;
			padding-right: 12px;
			transform: rotate(180deg);
		}
	}

	.cardInfo {
		display: flex;
		flex-direction: column;

		.cardInfoTitle {
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			letter-spacing: 0.03em;
			color: $grey;
		}

		.cardInfoText {
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			letter-spacing: 0.03em;
			display: flex;
			align-items: center;

			svg {
				margin-right: 8px;
			}

			span {
				color: $grey;
			}
		}
	}

	.cardInfoSubtotal {
		margin: 0 -20px;
		margin-top: 10px;
		padding: 8px 20px;
		border-top: 1px solid $borderColor;
		color: $yellow;
		font-weight: 600;
		font-size: 14px;
		line-height: 21px;
		margin-bottom: -12px;

		span {
			margin-left: 12px;
		}
	}
}