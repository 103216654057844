@import "src/assets/styles/variables";

.container {
	padding: 24px;
	background: linear-gradient(180deg, #181F27 0%, #0E151D 100%);
	border-radius: 20px;
	max-width: 335px;

	@media (min-width: $sm) {
		max-width: 424px;
		box-sizing: border-box;
		padding: 40px 44px;
	}

	.cliContainer {
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 6px;

		@media (min-width: $sm) {
			margin-bottom: 6px;
		}

		.cliHeading {
			font-weight: 600;
			color: $yellow;
			font-size: 16px;
			line-height: 24px;

			@media (min-width: $sm) {
				font-size: 18px;
				line-height: 27px;
			}

			.cliNumber {
				font-weight: 400;
				color: $white;
			}
		}

		.iconContainer {
			background: $lightBlue;
			display: flex;
			margin-left: 8px;
			padding: 6px;
			cursor: pointer;
			//border: 1px solid #313942;
			border-radius: 10px;
			width: 20px;
			height: 20px;
			align-items: center;
			justify-content: center;
		}
	}

	.voiceContainer {
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 16px;

		.cliHeading {
			align-items: center;
			display: flex;
			flex-direction: row;
			font-weight: 600;
			color: $yellow;
			font-size: 16px;
			line-height: 24px;

			@media (min-width: $sm) {
				font-size: 18px;
				line-height: 27px;
			}

			.cliNumber {
				margin-left: 8px;
				font-weight: 400;
				color: $white;
			}
		}
	}

	.buttonContainer {
		margin-top: 24px;
		display: flex;
		flex-direction: column;

		.buttonRow {
			display: flex;
			justify-content: space-between;
			margin-bottom: 8px;
			gap: 8px;

			@media (min-width: $sm) {
				gap: 12px;
				margin-bottom: 12px;
			}
		}
	}
}