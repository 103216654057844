@import "src/assets/styles/variables";

.container {
	padding: 0 20px;
	margin: 0 auto;

	.badgeGroup {
		max-width: calc(160px + 160px + 16px);
		display: flex;
		justify-content: space-between;
		gap: 15px;
		margin: 0 auto 16px;
		box-sizing: border-box;
		position: relative;

		@media (min-width: $sm) {
			max-width: calc(286px + 286px + 16px);
		}

		&:last-of-type {
			margin-top: 24px;

			@media (min-width: $sm) {
				margin-top: 40px;
				padding: 0 23px;
			}
		}

		.absolutText {
			position: absolute;
			bottom: 21px;
			left: 0;
			right: 0;
			text-align: center;
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			color: $grey;
		}

		& > button {
			width: 100%;

			@media (min-width: $sm) {
				max-width: 240px;
			}
		}
	}

	.buttonTextContainer {
		display: inline-block;

		& > span {
			display: block;

			@media (min-width: $sm) {
				display: inline-block;
			}
		}

		& > span:first-child {
			font-weight: 500;
			font-size: 10px;
			line-height: 15px;
			letter-spacing: 0.03em;

			@media (min-width: $sm) {
				font-weight: 600;
				font-size: 18px;
				line-height: 27px;
			}
		}

		& > span:last-child {
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 0.03em;

			@media (min-width: $sm) {
				font-weight: 600;
				font-size: 18px;
				line-height: 27px;
			}
		}
	}
}

.notEnoughFundsContainer {
	padding: 8px 12px;
	background: $lightBlueBg;
	max-width: 320px;
	box-sizing: border-box;
	border-radius: 5px;
	margin-bottom: 16px;

	span {
		font-size: 12px;
		line-height: 18px;
		display: block;
		letter-spacing: 0.03em;
		white-space: pre-wrap;
	}
}