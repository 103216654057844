@import "src/assets/styles/variables";

.button {
	width: 88px;
	height: 62px;
	background: $lightBlueBg;
	color: $white;
	border-radius: 20px;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.subtitle {
		height: 16px;
		color: $borderColor;
		font-size: 11px;
		line-height: 16px;

		@media (min-width: $sm) {
			font-size: 12px;
			line-height: 14px;
		}
	}

	@media (min-width: $sm) {
		font-size: 28px;
		line-height: 38px;
		width: 96px;
		height: 64px;

		@media (hover:hover) {
			&:hover {
				.subtitle {
					color: $white;
				}
			}
		}
	}

	&:disabled {
		color: $borderColor;
	}

	&:active:not(:disabled) {
		.subtitle {
			color: $white
		}

		@media (min-width: $sm) {
			background-color: $darkBlue;
		}
	}
}