@import "src/assets/styles/variables";

.container {
	width: 100%;
	display: flex;
	padding: 12px;
	border-radius: 10px;
	border: 1px solid $borderColor;
	box-sizing: border-box;

	@media (min-width: $sm) {
		padding: 24px;
		align-items: flex-start;
	}

	.icon {
		margin-right: 12px;
		height: 40px;
		flex-shrink: 0;

		@media (min-width: $sm) {
			height: 84px;
			margin-right: 24px;
			width: 84px;

			& > svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	.textContainer {
		height: fit-content;
		width: 84px;

		@media (min-width: $sm) {
			width: 128px;
		}

		& > span {
			display: block
		}

		.title {
			font-weight: 500;
			font-size: 10px;
			line-height: 15px;
			letter-spacing: 0.03em;
			color: $yellow;
			white-space: pre;

			@media (min-width: $sm) {
				font-size: 12px;
				line-height: 18px;
			}
		}

		.text > div {
			font-weight: 600;
			line-height: 1.5em;

			@media (min-width: $sm) {
				line-height: 1.5em;
			}
		}
	}
}