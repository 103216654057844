@import "src/assets/styles/variables";

.container {
	margin: 0 auto;
	padding-top: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	max-width: 502px;

	@media (min-width: $sm) {
		width: 502px;
		padding-top: 40px;
	}

	.inputBox {
		width: 100%;
		box-sizing: border-box;
		border-radius: 20px;
		border: 1px solid $borderColor;
		margin-bottom: 24px;
		padding: 16px 24px;

		@media (min-width: $sm) {
			padding: 24px 40px;
		}

		.heading {
			font-weight: 600;
			text-align: center;
			margin-bottom: 12px;
			font-size: 16px;
			line-height: 24px;

			@media (min-width: $sm) {
				font-size: 18px;
				line-height: 27px;
			}
		}

		.optionsContainer {
			display: flex;
			gap: 0;
			margin-top: 12px;
			cursor: pointer;

			@media (min-width: $sm) {
				margin-bottom: 12px;
			}

			.option {
				width: 100%;
				border: 1px solid $borderColor;
				text-align: center;
				color: $grey;
				padding: 6px;
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;

				&:first-child {
					border-bottom-left-radius: 10px;
					border-top-left-radius: 10px;
				}

				&:last-child {
					border-bottom-right-radius: 10px;
					border-top-right-radius: 10px;
				}

				&.optionActive {
					border-color: $yellow;
					color: $white;
				}
			}
		}

		.greyText {
			display: inline-block;
			text-align: center;
			color: $grey;
			font-weight: 500;
			font-size: 12px;
			line-height: 12px;

			@media (min-width: $sm) {
				display: block;
				line-height: 18px;
			}
		}

		input {
			font-weight: 600;
			font-size: 26px;
			line-height: 26px;

			@media (min-width: $sm) {
				font-size: 32px;
				line-height: 48px;
			}
		}
	}

	.currencyContainer {
		width: 100%;
		box-sizing: border-box;
		border-radius: 20px;
		border: 1px solid $borderColor;
		margin-bottom: 24px;
		padding: 16px 24px;

		@media (min-width: $sm) {
			padding: 24px 40px;
		}

		.heading {
			font-weight: 600;
			text-align: center;
			margin-bottom: 12px;
			font-size: 16px;
			line-height: 24px;

			@media (min-width: $sm) {
				font-size: 18px;
				line-height: 27px;
			}
		}

		.currencyGrid {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			gap: 8px;
			justify-content: center;

			@media (min-width: $sm) {
				gap: 16px;
			}

			.currencyButton {
				display: flex;
				align-items: center;
				border: 1px solid $lightBlueBg;
				background: $lightBlueBg;
				border-radius: 10px;
				padding: 8px 12px;
				cursor: pointer;
				color: $grey;

				@media (min-width: $sm) {
					padding: 12px 24px;
				}

				svg {
					height: 24px;
					width: 24px;
					margin-right: 12px;
				}

				.currencyButtonText {
					font-weight: 600;
					font-size: 14px;
					line-height: 21px;

					@media (min-width: $sm) {
						font-size: 16px;
						line-height: 24px;
					}
				}
			}

			.currencyButtonActive {
				color: $white;
				border: 1px solid $yellow;
			}
		}
	}
}

.notEnoughFundsContainer {
	padding: 8px 12px;
	background: $lightBlueBg;
	max-width: 320px;
	box-sizing: border-box;
	border-radius: 5px;
	margin-bottom: 16px;
	z-index: 9999;

	span {
		font-size: 12px;
		line-height: 18px;
		display: block;
		letter-spacing: 0.03em;
	}
}