@import "src/assets/styles/variables";

.container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	outline: 0;
}

.defaultModal {
	background: linear-gradient(180deg, $lightBlueBg 0%, $darkBlue 100%);
	position: relative;
	padding: 32px 40px 40px;
	border-radius: 20px;
	min-width: 250px;

	@media (min-width: $sm) {
		padding: 40px;
		min-width: 288px;
	}
}

.closeButtonContainer {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;

	@media (hover: hover) {
		&:hover {
			svg > path {
				fill: $white
			}
		}
	}
}

.closeButton {
	position: absolute;
	top: 20px;
	right: 20px;
}

.heading {
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	text-align: center;

	@media (min-width: $sm) {
		font-size: 24px;
		line-height: 36px;
	}
}

.text {
	display: block;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	width: 100%;
	white-space: pre-wrap;

	@media (min-width: $sm) {
		font-size: 16px;
		line-height: 24px;
	}
}

.yellowText {
	color: $yellow;
}

.notEnoughFundsContainer {
	padding: 8px 12px;
	background: $lightBlueBg;
	max-width: 320px;
	box-sizing: border-box;
	border-radius: 5px;
	margin-bottom: 16px;
	z-index: 9999;

	span {
		font-size: 12px;
		line-height: 18px;
		display: block;
		letter-spacing: 0.03em;
	}
}