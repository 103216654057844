@import "src/assets/styles/variables";

.container {
	display: flex;
	width: 100%;
	align-items: center;

	@media (min-width: $sm) {
		flex-direction: row !important;
		align-items: center;
	}

	.text {
		font-size: 14px;
		line-height: 21px;
		display: block;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 24px;
		}
	}

	.numberPlaceholder {
		width: 110px;
		height: 20px;
		background: $borderColor;
		border-radius: 5px;
	}

	.lockIcon {
		margin-left: 4px;
		display: flex;
		height: 16px;
	}
}

.copiedContainer {
	display: flex;
	background: $lightBlueBg;
	border-radius: 5px;
	height: 19px;
	box-sizing: border-box;
	align-items: center;
	justify-content: center;
	padding: 2px 6px;
	margin-top: 4px;
	.copied {
		display: block;
		font-weight: 600;
		font-size: 10px;
		line-height: 15px;
	}
}
