@import "src/assets/styles/variables";

.container {
	border: 1px solid $borderColor;
	border-radius: 20px;
	padding: 24px;
	margin-top: 16px;

	.title {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;

		@media (min-width: $sm) {
			text-align: left;
			font-size: 18px;
			line-height: 27px;
		}
	}

	.searchContainer {
		display: flex;
		align-items: center;
		border: 1px solid $borderColor;
		border-radius: 10px;
		margin: 12px 0;

		input {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			text-align: left;
			padding-left: 16px;

			&:focus::placeholder {
				color: transparent;
			}

			@media (min-width: $sm) {
				padding-left: 24px;
			}
		}

		.searchIcon {
			width: 48px;
			height: 48px;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			border-right: 1px solid $borderColor;
		}
	}

	.countryContainer {
		height: 273px;
		overflow-y: auto;
		margin-right: -12px;
		padding-right: 12px;

		.country {
			cursor: pointer;
			height: 45px;
			font-weight: 600;
			font-size: 14px;
			line-height: 21px;
			color: $grey;
			background: $lightBlueBg;
			border: 1px solid $lightBlueBg;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 12px;
			border-radius: 10px;
			padding: 0 16px;
			overflow: hidden;

			@media (min-width: $sm) {
				margin-bottom: 0;
			}

			span {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				text-align: center;
				display: block;
				width: 100%;
				margin: 0 auto;
			}
		}

		.countryActive {
			border-color: $yellow;
			color: $white;
		}
	}
}