@import "src/assets/styles/variables";

.headingYellow {
	color: $yellow;
}

.text {
	display: block;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	width: 100%;
	white-space: pre;

	@media (min-width: $sm) {
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
	}
}

.smallGreyText {
	font-weight: 400;
	color: #4B5A67;
	font-size: 10px;
	line-height: 15px;
	display: inline-block;
	text-align: center;
	white-space: pre;

	@media (min-width: $sm) {
		font-size: 14px;
		line-height: 21px;
	}
}

.button {
	padding: 6px 24px;
	display: flex;
	margin: 0 auto;
	width: fit-content;
	align-items: center;
	text-decoration: none;
	gap: 8px;
	border: 1px solid #5865F2;
	border-radius: 7px;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	color: $white;

	@media (min-width: $sm) {
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		padding: 6px 16px;
	}

	.discord {
		height: 16px;

		@media (min-width: $sm) {
			height: 24px;
		}

		svg {
			height: 100%;
		}
	}

	.arrow {
		height: 8px;
		display: flex;

		@media (min-width: $sm) {
			height: 12px;
		}

		svg {
			height: 100%;
		}
	}

}