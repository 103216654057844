@import "../../../../../../assets/styles/variables";

.container {
	max-width: 400px;
	margin: 0 auto;

	@media (min-width: $sm) {
		max-width: initial;
	}
}

.searchContainer {
	display: flex;
	align-items: center;
	border: 1px solid $borderColor;
	border-radius: 10px;

	input {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		text-align: left;
		padding-left: 16px;

		&:focus::placeholder {
			color: transparent;
		}

		@media (min-width: $sm) {
			padding-left: 24px;
		}
	}

	.searchIcon {
		width: 48px;
		height: 48px;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		border-right: 1px solid $borderColor;
	}
}

.tableHeader {
	padding: 11px 24px;
	background: $lightBlueBg;
	border-radius: 10px;

	& > div {
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.03em;
		color: $grey;
	}
}

.tableRow {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	padding: 12px 24px;
	height: 24px;
	align-items: center;
	border-bottom: 1px solid $borderColor;

	.tableRowCountryName {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
	}

	.tableRowType {
		display: flex;
		align-items: center;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;

		svg {
			margin-right: 8px;
			height: 24px;
			width: 16px;
		}
	}

	.icon {
		cursor: pointer;
		transform: rotate(180deg);
		height: 24px;
	}

	span {
		color: $grey;
	}
}

.emptyText {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: $grey;
	text-align: center;

	@media (min-width: $sm) {
		text-align: initial;
	}
}