@import "src/assets/styles/variables";

.container {
	border: 1px solid $borderColor;
	border-radius: 10px;

	.header {
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
		border-bottom: 1px solid $borderColor;
		padding: 8px 24px;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;

		@media (min-width: $sm) {
			font-size: 18px;
			line-height: 27px;
		}

		&.dark {
			background-color: $lightBlueBg;
		}
	}

	.content {
		padding: 12px 24px;
	}
}