@import "src/assets/styles/variables";

.button {
	width: 36px;
	height: 36px;
	background: transparent;
	border-radius: 10px;
	border: 1px solid $borderColor;
	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: relative;

	&.active {
		color: $yellow;
		background: $lightBlueBg;
		border-color: $lightBlueBg;
	}

	@media (hover: hover) {
		&:hover {
			border-color: $white;
		}
	}

	.redDot {
		background: $red;
		width: 4px;
		height: 4px;
		border-radius: 50%;
		position: absolute;
		top: 6px;
		right: 6px;
	}
}
