@import "src/assets/styles/variables";

.container {
	max-width: 900px;
	box-sizing: border-box;
	margin: 0 auto;
}

.title {
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	@media (min-width: $sm) {
		font-size: 24px;
		line-height: 36px;
	}
}

.text {
	font-size: 14px;
	line-height: 21px;

	@media (min-width: $sm) {
		font-size: 16px;
		line-height: 24px;
	}
}

.smallText {
	font-size: 10px;
	line-height: 15px;
	letter-spacing: 0.03em;
	color: $grey;
	display: block;

	@media (min-width: $sm) {
		font-size: 12px;
		line-height: 18px;
	}

	&.yellow {
		color: $yellow;
	}

	&.white {
		color: $white;
	}
}
