@import "src/assets/styles/variables";

.premiumContainer {
	width: 100%;
	border: 1px solid $borderColor;
	display: flex;
	border-radius: 10px;

	& > div {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 50%;
		padding: 5px;
		box-sizing: border-box;


		@media (min-width: $sm) {
			padding: 9.5px;
		}

		& > span {
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;

			@media (min-width: $sm) {
				font-size: 18px;
				line-height: 27px;
			}

			&:first-child {
				font-size: 12px;
				line-height: 18px;
				letter-spacing: 0.03em;
				color: $yellow;
			}
		}

		&:first-child {
			border-right: 1px solid $borderColor;
		}
	}
}