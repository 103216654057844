@import "src/assets/styles/variables";

.container {
	padding: 0px 20px 20px;
	max-width: 100%;
	width: 1130px;
	box-sizing: border-box;
	margin: 0 auto;

	@media (min-width: $sm) {
		padding-top: 8px;
	}

	.table {
		table-layout: auto;
		width: 100%;
		border-spacing: 0;
		border-collapse: collapse;
		margin-bottom: 25px;

		thead {
			tr {
				padding: 8px 0;
				border: none;
				height: 34px;
				box-sizing: border-box;

				@media (min-width: $sm) {
					padding: 11px 0;
				}

				th {
					padding: 0 12px;
					font-weight: 500;
					font-size: 12px;
					line-height: 18px;
					letter-spacing: 0.03em;
					box-sizing: border-box;
					white-space: nowrap;
					background: $lightBlueBg;
					text-align: left;
					color: $grey;

					@media (min-width: $sm) {
						padding: 0 24px;
					}

					&:first-child {
						border-bottom-left-radius: 10px;
						border-top-left-radius: 10px;
					}

					&:last-of-type {
						border-bottom-right-radius: 10px;
						border-top-right-radius: 10px;
					}

				}
			}
		}

		tbody {
			tr {
				border-bottom: 1px solid $borderColor;

				td {
					position: relative;
					vertical-align: top;
					padding: 50px 12px 12px;

					@media (min-width: $sm) {
						padding: 15px 24px;
						vertical-align: middle;
					}

					.badge {
						position: absolute;
						top: 12px;
						left: 12px;

						@media (min-width: $sm) {
							position: initial;
							width: fit-content;
						}
					}

					& > div {
						display: flex;
						flex-direction: column;
					}
				}
			}
		}
	}

	.text {
		display: block;
		font-size: 14px;
		line-height: 21px;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 24px;
		}
	}
}

.smallText {
	color: $grey;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.03em;

	@media (min-width: $sm) {
		font-size: 16px;
		line-height: 24px;
	}
}