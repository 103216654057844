.container {
	.title {
		display: flex;
		align-items: flex-start;
		margin-bottom: 19px;

		.icon {
			width: 30px;
			height: 30px;

			svg {
				width: 30px;
				height: 30px;
			}
		}
	}

	.content {
		//padding: 16px 24px;
	}
}