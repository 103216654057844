@import "src/assets/styles/variables";

.container {
	max-width: 900px;
	width: 100%;
	margin: 0 auto;

	.title {
		font-weight: 600;
		margin-left: 12px;
		font-size: 20px;
		line-height: 30px;

		@media (min-width: $sm) {
			font-size: 24px;
			line-height: 36px;
		}
	}

	.selectedBadge {
		cursor: pointer;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $lightBlueBg;
		border-radius: 5px;
		padding: 4px 4px 4px 12px;
		font-size: 14px;
		line-height: 21px;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 24px;
		}

		svg {
			height: 24px;
			margin-left: 8px;
		}
	}

	.subtitle {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;

		@media (min-width: $sm) {
			font-size: 18px;
			line-height: 27px;
			text-align: initial;
		}
	}

	.typeBox {
		cursor: pointer;
		display: flex;
		align-content: center;
		justify-content: center;
		color: $grey;
		border: 1px solid $borderColor;
		border-radius: 10px;
		width: 160px;
		padding: 7.5px 0;

		@media (min-width: $sm) {
			padding: 12px 0;
			width: 195px;
		}

		svg {
			margin-right: 6px;
			width: 16px;
			height: 21px;

			@media (min-width: $sm) {
				height: 24px;
			}
		}

		div {
			font-weight: 600;
			font-size: 14px;
			line-height: 21px;

			@media (min-width: $sm) {
				font-size: 16px;
				line-height: 24px;
			}
		}
	}

	.typeBoxActive {
		border-color: $yellow;
		color: $white;
	}
}