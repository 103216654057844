@import "src/assets/styles/variables";

.container {
	border: 1px solid $borderColor;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px 10px;

	.title {
		white-space: nowrap;
		margin-left: 4px;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.03em;
	}
}