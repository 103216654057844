@import "src/assets/styles/variables";

.container {
	margin: 0 auto;
	max-width: 900px;

	.card {
		max-width: 222px;
		box-sizing: border-box;
		border: 0.810811px solid $borderColor;
		box-shadow: 0 8.10811px 24.3243px rgba(14, 21, 29, 0.8);
		border-radius: 20px;
		background-color: $lightBlueBg;
		display: flex;
		padding: 16px;
		flex-direction: column;
		@media (min-width: $sm) {
			padding: 32px 24px;
		}

		.iconContainer {
			margin: 0 auto;
			width: 40px;
			height: 40px;

			@media (min-width: $sm) {
				width: 80px;
				height: 80px;
			}

			svg {
				width: 40px;
				height: 40px;

				@media (min-width: $sm) {
					width: 80px;
					height: 80px;
				}
			}
		}

		.cardTitle {
			margin-top: 8px;
			white-space: pre;
			text-align: center;
			font-weight: 600;
			font-size: 14px;
			line-height: 21px;

			@media (min-width: $sm) {
				margin-top: 24px;
				font-size: 18px;
				line-height: 27px;
			}
		}
	}
}