@import "src/assets/styles/variables";

.header {
	max-width: 400px;
	margin: 0 auto;
	box-sizing: border-box;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.03em;
	color: $grey;
	border-radius: 10px;
	padding: 11px 24px;
	background: $lightBlueBg;
	display: flex;
	justify-content: space-between;
}

.rows {
	margin: 0 auto;
	max-width: 400px;

	.row {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		padding: 11px 24px;
		border-bottom: 1px solid $borderColor;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;

		div {
			.elapsed {
				display: inline;
				font-weight: 500;
				font-size: 12px;
				line-height: 18px;
				letter-spacing: 0.03em;
			}

			span {
				font-weight: 500;
				font-size: 12px;
				line-height: 18px;
				letter-spacing: 0.03em;
				white-space: nowrap;
				color: $grey;
			}
		}
	}
}
