@import "src/assets/styles/variables";

.card {
	padding: 16px;
	background: $lightBlueBg;
	border-radius: 10px;
	width: 100%;
	box-sizing: border-box;

	@media (min-width: $sm) {
		padding: 20px 24px;
	}

	.cardHeader {
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;

		.cardHeaderDate {
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			letter-spacing: 0.03em;
			color: $grey;
			margin-bottom: 4px;
		}
	}

	.cardText {
		cursor: pointer;
		height: 48px;
		font-size: 14px;
		line-height: 21px;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 24px;
		}

		p {
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}
}