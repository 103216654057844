@import "../../../../../../../../../assets/styles/variables";

.addButton {
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.03em;
	color: $yellow;
	cursor: pointer;

	@media (min-width: $sm) {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
	}
}

.description {
	cursor: pointer;
	word-break: break-all;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.03em;

	@media (min-width: $sm) {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
	}
}

.form {
	input {
		padding: 0;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.03em;
		background: none;
		color: $white;

		@media (min-width: $sm) {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
		}
	}
}