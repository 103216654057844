@import "../../../../assets/styles/variables";

.container {
	padding: 24px 32px 0;
	border: 1px solid $borderColor;
	border-radius: 10px;
	position: relative;
	overflow: hidden;

	@media (min-width: $sm) {
		background-position: top left;
		background-repeat: no-repeat;
		background-size: 158px 158px;
		padding-top: 0;
	}

	&.containerActive {
		border-color: $yellow
	}

	.header {
		padding-bottom: 8px;
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		justify-content: space-between;

		& > span {
			display: block;
		}

		.headerTitle {
			font-size: 14px;
			line-height: 21px;
		}

		.headerPlan {
			font-weight: 600;
			font-size: 26px;
			line-height: 39px;
		}

		.headerIcon > svg {
			height: 58px;
			width: 58px;
		}

		@media (min-width: $sm) {
			display: flex;
			align-items: center;
			padding: 24px 0;
			box-sizing: border-box;
			flex-direction: row;
			justify-content: initial;

			.headerIcon > svg {
				margin-right: 60px;
				height: 110px;
				width: 110px;
			}

			.headerTitle {
				font-size: 16px;
				line-height: 24px;
				margin-bottom: 2px;
			}

			.headerPlan {
				font-size: 32px;
				line-height: 36px;
			}
		}

	}

	// header
	.withDesktopButtons {
		padding-top: 24px;
	}

	.priceContainer {
		border-top: 1px solid $borderColor;
		padding-top: 16px;
		display: flex;
		padding-bottom: 12px;

		@media (min-width: $sm) {
			padding-bottom: 24px;
		}

		& span {
			display: block;
		}

		.priceContainerContent {
			width: 50%;
			display: flex;
			flex-direction: column;

			.priceContainerContentTitle {
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				color: $grey;
			}

			.priceContainerContentText {
				font-weight: 600;
				font-size: 18px;
				line-height: 27px;
			}
		}
	}

	// desktop
	.priceContainerContent {
		width: 160px;
		margin-right: 48px;
		display: flex;
		flex-direction: column;
		margin-bottom: 8px;

		&:last-child {
			width: 130px;
			margin-right: 0;
		}

		.priceContainerContentTitle {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: $white;
			margin-bottom: 2px;
		}

		.priceContainerContentText {
			font-weight: 600;
			font-size: 24px;
			line-height: 36px;
		}
	}

	.timer {
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
		margin-top: 3px;

		@media (min-width: $sm) {
			margin-left: 8px;
		}
	}

	.buttons {
		display: flex;
		flex-direction: row;
		margin-bottom: 24px;
		@media (min-width: $sm) {
			margin-bottom: 0;
		}

		.button {
			padding: 4px 10px;
			font-weight: 600;
			font-size: 14px;
			line-height: 21px;
			opacity: 1;
			border-radius: 10px;
			margin-right: 12px;
			cursor: pointer;
			border: 1px solid $borderColor;

			@media (hover: hover) {
				&:hover:not(.buttonDisabled) {
					border-color: $yellow;
				}
			}

			&:active:not(.buttonDisabled) {
				border-color: $yellow;
				background-color: $yellow;
				color: $darkBlue;
			}

			&.buttonDisabled {
				opacity: 0.3;
				background: $lightBlue;
			}

			@media (min-width: $sm) {
				font-size: 16px;
				line-height: 24px;
				padding: 5px 22px;
			}
		}
	}

	.footer {
		margin-left: -33px;
		margin-right: -33px;
		margin-bottom: -1px;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		display: flex;
		flex-direction: column;
		padding: 12px 32px;
		border-top: 1px solid $borderColor;

		@media (min-width: $sm) {
			flex-direction: row;
			justify-content: space-between;
		}

		& > span {
			white-space: nowrap;
			display: block;
			font-size: 12px;
			line-height: 18px;
			font-weight: 600;
			color: $grey;
			margin-bottom: 2px;

			& > strong {
				color: $white;
				margin-left: 8px;
			}
		}

		&.footerActive {
			color: $darkBlue;
			background-color: $yellow;
			border-color: $yellow;

			& > span {
				white-space: nowrap;
				display: block;
				font-size: 12px;
				line-height: 18px;
				font-weight: 600;
				color: $darkBlue;
				margin-bottom: 2px;

				& > strong {
					color: $darkBlue;
				}
			}
		}

	}

	.autoContainer {
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 10px;
		line-height: 15px;
		margin-top: 2px;

		& > span:last-of-type {
			margin-left: 8px;
		}

		@media (min-width: $sm) {
			font-size: 12px;
			line-height: 18px;
		}
	}

	.promoContainer {
		position: absolute;
		top: 12px;
		right: -58px;

		@media (min-width: $sm) {
			top: 20px;
			right: -45px;
		}

		.promoBanner {
			background: $red;
			font-weight: 600;
			color: $white;
			display: flex;
			align-items: center;
			justify-content: center;
			transform: rotate(45deg);
			font-size: 7.81395px;
			line-height: 12px;
			width: 152px;
			height: 14px;

			@media (min-width: $sm) {
				font-size: 12px;
				line-height: 18px;
				width: 152px;
				height: 24px;
			}
		}
	}

}

.notEnoughFundsContainer {
	padding: 8px 12px;
	background: $lightBlueBg;
	max-width: 320px;
	box-sizing: border-box;
	border-radius: 5px;
	margin-top: 16px;

	span {
		font-size: 12px;
		line-height: 18px;
		display: block;
		letter-spacing: 0.03em;
	}

	& .highlighted {
		cursor: pointer;
		color: $yellow;
	}
}