@import "../../../../assets/styles/variables";

.container {
	padding: 24px 32px 0;
	border: 1px solid $borderColor;
	border-radius: 10px;

	&.containerActive {
		border-color: $yellow
	}

	.header {
		border-bottom: 1px solid $borderColor;
		padding-bottom: 16px;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: space-between;

		& > span {
			display: block;
		}

		.headerTitle {
			font-size: 14px;
			line-height: 21px;
		}

		.headerPlan {
			font-weight: 600;
			font-size: 26px;
			line-height: 39px;
		}

		.headerIcon > svg {
			height: 58px;
			width: 58px;
		}
	}

	.priceContainer {
		padding-top: 16px;
		padding-bottom: 24px;
		display: flex;

		& span {
			display: block;
		}

		.priceContainerContent {
			width: 50%;
			display: flex;
			flex-direction: column;

			.priceContainerContentTitle {
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				color: $grey;
			}

			.priceContainerContentText {
				font-weight: 600;
				font-size: 18px;
				line-height: 27px;
			}
		}
	}

	// desktop
	.priceContainerContent {
		width: 160px;
		margin-right: 48px;
		display: flex;
		flex-direction: column;

		.priceContainerContentTitle {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: $white;
			margin-bottom: 2px;
		}

		.priceContainerContentText {
			font-weight: 600;
			font-size: 24px;
			line-height: 36px;
		}
	}

	.footer {
		margin-left: -33px;
		margin-right: -33px;
		margin-bottom: -1px;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		display: flex;
		flex-direction: column;
		padding: 8px 32px;
		border-top: 1px solid $borderColor;

		& > span {
			white-space: nowrap;
			display: block;
			font-size: 12px;
			line-height: 18px;
			font-weight: 600;
			letter-spacing: 0.03em;
			color: $grey;
			margin-bottom: 2px;

			& > strong {
				color: $white;
				margin-left: 8px;

			}
		}

		&.footerActive {
			color: $darkBlue;
			background-color: $yellow;
			border-color: $yellow;

			& > span {
				white-space: nowrap;
				display: block;
				font-size: 12px;
				line-height: 18px;
				font-weight: 600;
				color: $darkBlue;
				margin-bottom: 2px;

				& > strong {
					color: inherit;
				}
			}
		}

	}
}