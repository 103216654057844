.container {
	padding-top: 8px;
	padding-bottom: 8px;
	display: flex;
	flex-direction: column;

	.tabContainer {
		width: fit-content;
		margin: 0 auto;
	}
}