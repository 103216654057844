@import "src/assets/styles/variables";

.container {
	margin: 0 auto;
	max-width: 333px;

	@media (min-width: $sm) {
		max-width: 900px;
	}

	.title {
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;

		@media (min-width: $sm) {
			font-size: 24px;
			line-height: 36px;
		}
	}

	.blueText {
		color: $blueText;
	}

	.greyText {
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		color: $grey;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 24px;
		}
	}

	.separator {
		border-top: 1px solid $borderColor;
	}

	.boxHeader {
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		display: inline-block;
		margin-right: auto;

		@media (min-width: $sm) {
			font-size: 24px;
			line-height: 36px;
		}

		svg {
			margin: 0 8px;
			max-height: 24px;
			vertical-align: bottom;

			path {
				fill: $yellow;
			}
		}

		span {
			color: $yellow;
		}
	}

	.smallTitle {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;

		@media (min-width: $sm) {
			font-size: 18px;
			line-height: 27px;
		}
	}
}