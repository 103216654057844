@import "src/assets/styles/variables";

.cliHeading {
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	color: $yellow;

	.cliNumber {
		font-weight: 400;
		color: $white;
	}
}

.callerIdDescription {
	font-size: 12px;
	line-height: 18px;
	margin-top: 8px;
	margin-bottom: 16px;
	color: $grey;
	white-space: pre-wrap;
	text-align: center;
}

.random {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: $yellow;
	cursor: pointer;
	margin-top: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 8px;

	& > img {
		width: 16px;
		height: 16px;
		margin-right: 8px;
	}
}