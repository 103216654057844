@import "src/assets/styles/variables";

.container {
	.title {
		display: flex;
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		align-items: center;
		justify-content: center;
		gap: 12px;
		margin-bottom: 24px;

		@media (min-width: $sm) {
			justify-content: flex-start;
			font-size: 24px;
			line-height: 36px;
		}

		svg {
			height: 32px;
			width: 32px;
		}
	}

	.infoContainer {
		justify-content: space-between;
		padding: 12px 20px;
		border: 1px solid $borderColor;
		border-radius: 10px;
		display: flex;
		max-width: 330px;
		margin: 0 auto;
	}

	.infoLocation {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;

		@media (min-width: $sm) {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
		}
	}

	.infoTitleDesktop {
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: $grey;
	}

	.infoPlan {
		margin-top: auto;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: $grey;
		display: inline-block;

		& > div {
			display: inline-block;
			color: $white;
		}
	}

	.infoList {
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: $yellow;
		text-decoration: underline;
		cursor: pointer;
		letter-spacing: 0.03em;

		@media (min-width: $sm) {
			margin-left: 16px;
		}
	}

	.infoPrice {
		margin-top: auto;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: $grey;
		display: inline-block;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 24px;
		}

		& > div {
			display: inline-block;
			color: $white;
		}
	}
}

.notEnoughFundsContainer {
	padding: 8px 12px;
	background: $lightBlueBg;
	max-width: 320px;
	box-sizing: border-box;
	border-radius: 5px;
	margin-bottom: 16px;

	span {
		font-size: 12px;
		line-height: 18px;
		display: block;
		letter-spacing: 0.03em;
		white-space: pre-wrap;
	}

	& .highlighted {
		cursor: pointer;
		color: $yellow;
	}
}

.modalTitle {
	margin-bottom: 16px;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
}

.country {
	font-weight: 400;
	font-size: 14px;
	line-height: 190%;
}

.modalContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	outline: 0;
	background: linear-gradient(180deg, $lightBlueBg 0%, $darkBlue 100%);
	padding: 32px 40px 40px;
	border-radius: 20px;
	min-width: 250px;

	@media (min-width: $sm) {
		padding: 40px;
		min-width: 288px;
	}
}