@import "src/assets/styles/variables";

.container {
	display: flex;

	.infoContainer {
		display: flex;
		font-weight: 600;
		font-size: 14px;
		line-height: 14px;
		margin-right: 8.5px;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 16px;
			height: 36px;
		}

		.balance {
			border: 1px solid $borderColor;
			border-radius: 10px 0 0 10px;
			padding: 0 8px;
			display: flex;
			align-items: center;
		}

		.wallet {
			cursor: pointer;
			border: 1px solid $borderColor;
			border-radius: 0 10px 10px 0;
			padding: 0 8px;
			display: flex;
			align-items: center;

			&>span {
				display: block;
			}

			.icon {
				width: 16px;
				height: 16px;
				margin-right: 8px;

				& > path {
					fill: $yellow
				}
			}

			.copyIcon {
				margin-left: 4px;
				cursor: pointer;

				@media (min-width: $sm) {
					margin-left: 6px;
				}

				&.active {
					path {
						fill: $yellow;
					}
				}

				@media (hover:hover) {
					&:hover {
						path {
							fill: $white;
						}
					}
				}
			}
		}
	}
}

.copiedContainer {
	display: flex;
	background: $lightBlueBg;
	border-radius: 5px;
	height: 19px;
	box-sizing: border-box;
	align-items: center;
	justify-content: center;
	padding: 2px 6px;
	margin-top: 4px;
	.copied {
		display: block;
		font-weight: 600;
		font-size: 10px;
		line-height: 15px;
	}
}
