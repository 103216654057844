@import "src/assets/styles/variables";

.container {
	max-width: 1360px;
	width: 100%;
	margin: 8px auto 0;

	.tableHeader {
		padding: 11px 24px;
		background: $lightBlueBg;
		border-radius: 10px;

		& > div {
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			letter-spacing: 0.03em;
			color: $grey;
		}
	}
}