@import "src/assets/styles/variables";

.modalContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	outline: 0;
	background: linear-gradient(180deg, $lightBlueBg 0%, $darkBlue 100%);
	border-radius: 20px;
	width: 100%;
	padding: 24px;
	max-width: 320px;
	box-sizing: border-box;

	@media (min-width: $sm) {
		padding: 32px 40px;
		min-width: 400px;
		max-width: 480px;
	}

	.modalTitle {
		color: $yellow;
		display: flex;
		align-items: center;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.03em;

		svg {
			margin-right: 8px;
		}
	}

	.text {
		font-size: 14px;
		line-height: 21px;
		white-space: pre-wrap;
		max-height: 200px;
		overflow-y: auto;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 24px;
		}
	}
}

