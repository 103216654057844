@import "src/assets/styles/variables";

.addressContent {
	display: flex;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;

	@media (min-width: $sm) {
		//width: calc(100% - 250px);
	}

	.greySmallText {
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: $grey;
	}

	.label {
		color: $yellow;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		display: block;
		margin-bottom: 4px;
	}

	.field {
		border-radius: 10px;
		padding: 6px 12px;
		border: 1px solid $borderColor;
		display: flex;
		align-items: center;
		cursor: pointer;
		box-sizing: border-box;
		width: fit-content;
		max-width: 100%;

		.text {
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
			width: fit-content;
			white-space: nowrap;
		}

		.copyIcon {
			padding-left: 8px;
			width: 13px;
			flex-shrink: 0;
			margin-left: auto;
			cursor: pointer;

			@media (min-width: $sm) {
				margin-left: 6px;
			}

			&.active {
				path {
					fill: $yellow;
				}
			}

			@media (hover: hover) {
				&:hover {
					path {
						fill: $white;
					}
				}
			}
		}

		svg {
			justify-self: flex-end;
		}
	}
}

.copiedContainer {
	display: flex;
	background: $lightBlueBg;
	border-radius: 5px;
	height: 19px;
	box-sizing: border-box;
	align-items: center;
	justify-content: center;
	padding: 2px 6px;
	margin-top: 4px;

	.copied {
		display: block;
		font-weight: 600;
		font-size: 10px;
		line-height: 15px;
	}
}
