@import "src/assets/styles/variables";

.container {
	height: 123px;
	padding: 16px 20px 0;
	box-sizing: border-box;

	@media (min-width: $sm) {
		padding: 0;
		height: 88px;
		width: 100%;
	}

	.headerTop {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (min-width: $sm) {
			max-width: calc(1600px - 240px);
			margin: 0 auto;
			height: 88px;
			padding: 0 16px;
			box-sizing: border-box;
		}

		.logo {
			display: flex;
			align-items: center;
			height: 25px;
			width: auto;
			@media (min-width: $sm) {
				height: 34px;
			}
		}

		.desktopTitle {
			display: none;
			font-size: 32px;
			line-height: 48px;
			font-weight: 600;

			& > span {
				display: block;
			}

			@media (min-width: $sm) {
				display: flex;
				align-items: center;
			}
		}
	}

	.headerBottom {
		margin-top: 32px;
		display: flex;
		justify-content: center;
		align-items: center;

		@media (min-width: $sm) {
			display: none;
		}

		.title {
			@include mobileH1
		}
	}

	.backButton {
		margin-right: 12px;

		@media (min-width: $sm) {
			margin-right: 24px;
			width: 36px;
			height: 36px;
		}

	}
}

