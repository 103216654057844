@import "src/assets/styles/variables";

.menuContainer {
	margin-top: 16px;
	background: $lightBlueBg;
	border: 1px solid $borderColor;
	padding: 8px 12px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;

	.menuItem {
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;
		display: flex;
		justify-content: space-between;
		padding: 6px 12px;
		text-decoration: none;
		color: $white;
		min-width: 196px;
		box-sizing: border-box;

		@media (min-width: $sm) {
			color: $grey;

			@media (hover: hover) {
				&:hover {
					color: $white;
				}
			}
		}

		.redDot {
			background: $red;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			margin-right: auto;
			margin-left: 3px;
			margin-top: 3px;
		}

		.icon {
			height: 24px;
		}

		@media (hover: hover) {
			&:hover {
				.icon {
					svg > path, svg > g > path, svg g path {
						fill: $white
					}
				}
			}
		}
	}
}