.container {
	width: 100%;
	max-width: 900px;
	margin: 0 auto;

	.svgContainer {
		svg {
			width: 100%;
		}
	}
}
