.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1;
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
}
