
.container {
	background: rgba(15, 92, 208, 0.15);
	border-radius: 10px;
	padding: 6px 12px;
	display: flex;

	.icon {
		margin-right: 8px;
		display: flex;

		svg {
			width: 16px;
			height: 16px;
		}
	}

	.text {
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.03em;
	}
}