@import "src/assets/styles/variables";

.input {
	background: transparent;
	color: $white;
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	text-align: center;
	width: 100%;
	box-sizing: border-box;
	outline: none;

	&::placeholder {
		color: $grey;
	}

	&:disabled {
		color: $borderColor;

		&::placeholder {
			color: $borderColor;
		}
	}
}

.textarea {
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	padding: 0;
	resize: none;
	border: none;
	height: 100%;
	position: absolute;
	inset: 0;
	max-lines: 1;
	font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
}