@import "src/assets/styles/variables";

.container {
	padding-bottom: 16px;
	margin: 0 auto;

	@media (min-width: $sm) {
		display: flex;
		box-sizing: border-box;
		padding: 8px 0;
	}

	.keypadContainer {
		margin: 0 auto;
		padding: 0 20px;
		justify-content: center;
		display: flex;
	}
}