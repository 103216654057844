@import "src/assets/styles/variables";

.container {
	background: $lightBlueBg;
	padding: 6px 12px;
	cursor: pointer;

	.text {
		font-weight: 400;
		font-size: 10px;
		line-height: 15px;
		letter-spacing: 0.03em;
		color: $yellow;
		display: block;

		@media (min-width: $sm) {
			font-size: 12px;
			line-height: 18px;
		}
	}

	.select {
		display: flex;
		align-items: center;
	}

	.currency {
		font-weight: 600;
		font-size: 14px;
		line-height: 14px;
		margin-left: 8px;
		margin-right: 8px;

		@media (min-width: $sm) {
			line-height: 16px;
			font-size: 16px;
		}
	}

}

.currencyBadge {
	background: $lightBlueBg;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	padding: 14px 16px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	margin-top: 8px;
	cursor: pointer;
	justify-content: center;

	& > span {
		display: block;
		margin-left: 12px;
	}
}