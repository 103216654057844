@import "src/assets/styles/variables";

.container {
	overflow: hidden;
	margin-right: -24px;
	margin-left: -24px;
	margin-bottom: 40px;

	.row {
		display: flex;
		margin-bottom: 8px;

		.wallet {
			height: 34px;
			box-sizing: border-box;
			background-color: $lightBlue;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 5px;
			padding: 9px 17px;
			margin-right: 8px;

			& > svg {

			}
		}

		.walletDark {
			opacity: 0.5;
		}

		.walletDarker {
			opacity: 0.2;
		}
	}
}