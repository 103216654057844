@import "src/assets/styles/variables";

.container {
	border-radius: 20px;
	border: 1px solid $borderColor;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 59px;
	padding: 16px;
	box-sizing: border-box;
	transition: border-color 250ms linear, padding-top 250ms linear;
	position: relative;

	& input {
		padding: 0;
	}

	@media (min-width: $sm) {
		height: 72px;

		& input {
			font-weight: 600;
			font-size: 24px;
			line-height: 36px;
		}
	}

	&.containerActive {
		border-color: $yellow;
	}

	&.withSubtitle {
		padding-top: 9px;
		justify-content: flex-start;

		@media (min-width: $sm) {
			padding-top: 11px;
		}
	}

	&.withoutSubtitle {

	}

	.info {
		width: 100%;
		transition: max-height 250ms linear, opacity 250ms ease-in;
		height: 18px;
		position: absolute;

		& > div {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&.infoActive {
			max-height: 18px;
			bottom: 7px;
			opacity: 1;

			@media (min-width: $sm) {
				bottom: 10px;
			}
		}

		&.infoInActive {
			max-height: 0;
			opacity: 0;
			margin-bottom: 0;
		}
	}

	.rate {
		letter-spacing: 0.03em;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		color: $yellow;
	}

	.callStatus {
		letter-spacing: 0.03em;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		color: $green;

		&.noAnswer {
			color: $yellow
		}
	}

	.elapsed {
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.03em;
	}
}