@import "src/assets/styles/variables";

.button {
	border: 1px solid $borderColor;
	border-radius: 7px;
	display: flex;
	align-items: center;
	max-width: 100%;
	width: 125px;
	background: transparent;
	color: $white;
	padding: 6px 0;
	justify-content: center;
	cursor: pointer;

	@media (min-width: $xs) {
		width: 162px;
	}

	@media (hover: hover) {
		&:hover {
			color: $yellow;
		}
	}

	svg {
		@media (min-width: $xs) {
			width: 24px;
			height: 24px;
		}
	}

	span {
		margin-left: 8px;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;

		@media (min-width: $xs) {
			font-size: 17.5px;
			line-height: 26.25px;
		}
	}
}