@import "src/assets/styles/variables";

.headingYellow {
	color: $yellow;
}

.text {
	display: block;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	width: 100%;
	white-space: pre-wrap;

	@media (min-width: $sm) {
		font-size: 16px;
		line-height: 24px;
	}
}