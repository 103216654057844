@import "src/assets/styles/variables";

.container {
	.text {
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		color: $grey;
		text-align: center;

		@media (min-width: $sm) {
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
		}
	}

	.paginationContainer {
		width: 335px;
		margin: 16px auto 0;

		@media (min-width: $sm) {
			margin-top: 24px;
			width: 900px;
		}
	}
}

