@import "src/assets/styles/variables";

.modalTitle {
	margin-bottom: 16px;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
}

.modalContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	outline: 0;
	background: linear-gradient(180deg, $lightBlueBg 0%, $darkBlue 100%);
	padding: 32px 40px 40px;
	border-radius: 20px;
	min-width: 250px;

	.grid {
		min-width: 288px;
		display: grid;
		grid-template-columns: repeat(3, auto);
		column-gap: 16px;

		@media (min-width: $md) {
			grid-template-columns: repeat(4, auto);
		}

		.country {
			height: 30px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			width: 250px;
		}
	}
}