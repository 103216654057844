@import "../../../../assets/styles/variables";

.container {
	padding: 24px 32px 0;
	border: 1px solid $borderColor;
	border-radius: 10px;
	position: relative;
	overflow: hidden;

	@media (min-width: $sm) {
		background-position: top left;
		background-repeat: no-repeat;
		background-size: 158px 158px;
		padding-top: 0;
	}

	&.containerActive {
		border-color: $yellow
	}

	.header {
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		justify-content: space-between;

		& > span {
			display: block;
		}

		.headerTitle {
			font-size: 14px;
			line-height: 21px;
		}

		.headerPlan {
			font-weight: 600;
			font-size: 26px;
			line-height: 39px;
		}

		.headerIcon > svg {
			height: 58px;
			width: 58px;
		}

		@media (min-width: $sm) {
			display: flex;
			padding: 32px 0;
			box-sizing: border-box;
			flex-direction: row;
			justify-content: initial;
			align-items: flex-start;

			.headerIcon > svg {
				margin-right: 42px;
				height: 128px;
				width: 128px;
			}

			.headerTitle {
				font-size: 16px;
				line-height: 24px;
				margin-bottom: 2px;
			}

			.headerPlan {
				font-size: 32px;
				line-height: 36px;
			}
		}

		.headerContent {
			display: flex;
			flex-direction: column;

			.headerContentRow {
				display: flex;

				&:first-child {
					margin-bottom: 16px;
				}

				.footer {
					font-size: 12px;
					line-height: 18px;

					& > div {
						white-space: nowrap;
						display: inline-block;
						font-size: 12px;
						line-height: 18px;
						font-weight: 600;
						color: $grey;
						letter-spacing: 0.03em;

						& > strong {
							color: $yellow;
							margin-left: 8px;
						}

						&:first-of-type {
							margin-bottom: 6px;
						}
					}

					.divider {
						display: inline-block;
						font-size: 12px;
						line-height: 18px;
						color: $borderColor;
						margin: 0 8px;
						font-style: normal;
						font-weight: 500;
						letter-spacing: 0.03em;
					}
				}
			}
		}

	}

	// header
	.withDesktopButtons {
		//padding-top: 24px;
	}

	.priceContainer {
		border-top: 1px solid $borderColor;
		padding-top: 16px;
		display: flex;
		padding-bottom: 12px;

		@media (min-width: $sm) {
			padding-bottom: 24px;
		}

		& span {
			display: block;
		}

		.priceContainerContent {
			width: 50%;
			display: flex;
			flex-direction: column;

			.priceContainerContentTitle {
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				color: $grey;
			}

			.priceContainerContentText {
				font-weight: 600;
				font-size: 18px;
				line-height: 27px;
			}
		}
	}

	// desktop
	.priceContainerContent {
		width: 160px;
		margin-right: 48px;
		display: flex;
		flex-direction: column;
		margin-bottom: 8px;

		&:last-child {
			width: 130px;
			margin-right: 0;
		}

		.priceContainerContentTitle {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: $white;
			margin-bottom: 2px;
		}

		.priceContainerContentText {
			font-weight: 600;
			font-size: 24px;
			line-height: 36px;
		}
	}

	.timer {
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
		margin-top: 3px;

		@media (min-width: $sm) {
			margin-left: 8px;
		}
	}

	.buttons {
		display: flex;
		flex-direction: row;
		margin-top: 8px;

		.button {
			padding: 4px 10px;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			opacity: 1;
			border-radius: 10px;
			margin-right: 12px;
			cursor: pointer;
			border: 1px solid $borderColor;

			@media (hover: hover) {
				&:hover:not(.buttonDisabled) {
					border-color: $yellow;
				}
			}

			&:active:not(.buttonDisabled) {
				border-color: $yellow;
				background-color: $yellow;
				color: $darkBlue;
			}

			&.buttonDisabled {
				opacity: 0.3;
				background: $lightBlue;
			}

			@media (min-width: $sm) {
				font-size: 16px;
				line-height: 24px;
				padding: 5px 22px;
			}
		}
	}

	.autoContainer {
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 10px;
		line-height: 15px;
		margin-top: 2px;

		& > span:last-of-type {
			margin-left: 8px;
		}

		@media (min-width: $sm) {
			font-size: 12px;
			line-height: 18px;
		}
	}

	.promoContainer {
		position: absolute;
		top: 12px;
		right: -58px;

		@media (min-width: $sm) {
			top: 20px;
			right: -45px;
		}

		.promoBanner {
			background: $red;
			font-weight: 600;
			color: $white;
			display: flex;
			align-items: center;
			justify-content: center;
			transform: rotate(45deg);
			font-size: 7.81395px;
			line-height: 12px;
			width: 152px;
			height: 14px;

			@media (min-width: $sm) {
				font-size: 12px;
				line-height: 18px;
				width: 152px;
				height: 24px;
			}
		}
	}

}

.notEnoughFundsContainer {
	padding: 8px 12px;
	background: $lightBlueBg;
	max-width: 320px;
	box-sizing: border-box;
	border-radius: 5px;
	margin-top: 16px;

	span {
		font-size: 12px;
		line-height: 18px;
		display: block;
		letter-spacing: 0.03em;
	}

	& .highlighted {
		cursor: pointer;
		color: $yellow;
	}
}