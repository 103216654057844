@import "src/assets/styles/variables";

.container {
	background: $lightBlue;
	padding: 6px 12px;
	cursor: pointer;
	border-radius: 10px;

	.text {
		font-weight: 400;
		font-size: 10px;
		line-height: 15px;
		letter-spacing: 0.03em;
		color: $yellow;
		display: block;

		@media (min-width: $sm) {
			font-size: 12px;
			line-height: 18px;
		}
	}

	.select {
		display: flex;
		align-items: center;
	}

	.currency {
		font-weight: 600;
		font-size: 14px;
		line-height: 14px;
		margin-right: 8px;

		@media (min-width: $sm) {
			line-height: 16px;
			font-size: 16px;
		}
	}
}

.optionsContainer {
	background: $lightBlue;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	padding: 8px 20px;
	margin-top: 8px;

	.currencyBadge {
		margin-bottom: 4px;
		cursor: pointer;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: $grey;

		&.currencyBadge_selected {
			color: $white;
		}
	}
}
