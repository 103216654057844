@import "../../assets/styles/variables";

.container {
	padding: 16px 20px 20px;
	max-width: 900px;
	box-sizing: border-box;
	margin: 0 auto;

	@media (min-width: $sm) {
		padding-top: 32px;
	}

	.table {
		table-layout: auto;
		width: 100%;
		border-spacing: 0;
		border-collapse: collapse;
		margin-bottom: 25px;

		thead {

			tr {
				padding: 8px 0;
				border: none;
				height: 34px;
				box-sizing: border-box;
				@media (min-width: $sm) {
					padding: 11px 0;
				}

				th {
					padding: 0 12px;
					font-weight: 500;
					font-size: 12px;
					line-height: 18px;
					letter-spacing: 0.03em;
					box-sizing: border-box;
					white-space: nowrap;
					background: $lightBlueBg;
					text-align: left;

					@media (min-width: $sm) {
						padding: 0 24px
					}

					&:first-child {
						border-bottom-left-radius: 10px;
						border-top-left-radius: 10px;
					}

					&:last-of-type {
						border-bottom-right-radius: 10px;
						border-top-right-radius: 10px;
					}

				}
			}
		}

		tbody {
			tr {
				border-bottom: 1px solid $borderColor;

				td {
					position: relative;
					vertical-align: top;
					padding: 12px 12px 12px;

					@media (min-width: $sm) {
						padding: 12px 24px
					}

					& > div {
						display: flex;
						flex-direction: column;
					}
				}
			}
		}
	}

	.amount {
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 24px;
		}
	}

	.amountPositive {
		color: $greenLight;
	}

	.amountNegative {
		color: $redLight
	}

	.smallText {
		color: $grey;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.03em;
		display: block;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 24px;
		}

		&.center {
			text-align: center;
		}
	}

	.text {
		display: block;
		font-size: 14px;
		line-height: 21px;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 24px;
		}

		& span {
			@media (min-width: $sm) {
				color: $grey
			}
		}
	}
}