@import "src/assets/styles/variables";

.inputContainer {
	margin: 16px 0;
	border: 1px solid $borderColor;
	display: flex;
	justify-content: space-evenly;
	border-radius: 10px;
	overflow: hidden;

	@media (min-width: $sm) {
		align-items: center;
		justify-content: space-between;
		border-radius: 20px;
		padding-right: 16px;
		& > div {
			color: $grey;
			cursor: pointer;
		}
	}
}

.smallText {
	font-weight: 400;
	font-size: 10px;
	line-height: 15px;
	display: block;
	letter-spacing: 0.03em;
	opacity: 0.3;

	@media (min-width: $sm) {
		font-size: 12px;
		line-height: 18px;
	}
}

.smallTextActive {
	opacity: 1;
}