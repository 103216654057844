@import "src/assets/styles/variables";

.header {
	max-width: 1100px;
	margin: 0 auto;
	box-sizing: border-box;
	display: grid;
	gap: 12px;
	grid-template-columns: 120px 120px 250px 150px 100px 70px;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.03em;
	color: $grey;
	border-radius: 10px;
	padding: 11px 24px;
	background: $lightBlueBg;

	@media (min-width: $md) {
		gap: 48px;
	}
}

.rows {
	margin: 0 auto;
	max-width: 1100px;

	.row {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		padding: 11px 24px;
		display: grid;
		gap: 12px;
		grid-template-columns: 120px 120px 250px 150px 100px 70px;
		border-bottom: 1px solid $borderColor;
		align-items: center;

		@media (min-width: $md) {
			gap: 48px;
		}

		div {
			span {
				color: $grey;
			}
		}
	}
}
