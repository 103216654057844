@import "../../../../../../assets/styles/variables";

.modalContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	outline: 0;
	background: linear-gradient(180deg, $lightBlueBg 0%, $darkBlue 100%);
	border-radius: 20px;
	min-width: 260px;
	padding: 24px 32px;

	@media (min-width: $sm) {
		padding: 32px 40px 40px;
	}

	.modalTitle {
		white-space: pre-line;
		margin-bottom: 16px;
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		text-align: center;

		@media (min-width: $sm) {
			white-space: initial;
			font-size: 24px;
			line-height: 36px;
		}
	}

	@media (min-width: $sm) {
		padding: 40px;
		min-width: 288px;
	}

	.plan {
		border: 1px solid $borderColor;
		border-radius: 10px;
		padding: 12px 20px;

		& > div:first-child {
			border-bottom: 1px solid $borderColor;
			margin-bottom: 8px;
			padding-bottom: 8px;
		}

		.esimTitle {
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
		}

		.esimPlan {
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;

			& > span {
				color: $grey;
			}
		}

		.esimUntil {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			color: $grey;

			span {
				display: inline-block;
				margin-left: 4px;
				color: $white;
			}

			& > div {
				margin-bottom: 2px;
			}

			& > div:last-child {
				margin-bottom: 0;
			}
		}
	}

	.rowHeader {
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: $grey;
		letter-spacing: 0.03em;
	}

	.row {
		display: flex;
		align-items: flex-start;
		width: 800px;

		.rowText {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;

			span {
				color: $grey;
			}
		}
	}

	.danger {
		padding: 6px 12px;
		background-color: rgba(208, 15, 15, 0.15);
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.03em;
		border-radius: 5px;
		margin: 16px 0;

		@media (min-width: $sm) {
			width: fit-content;
			margin: 16px auto;
		}

		& span {
			color: $red;
		}
	}
}

