$yellow: #FBC04E;
$darkBlue: #0E151D;
$lightBlue: #1E2731;
$white: #fff;
$borderColor: #313942;
$lightBlueBg: #181F27;
$green: #0FD05C;
$red: #D00F0F;
//$grey: #4A5663;
$grey: #768696;
$redLight: #F53D6A;
$greenLight: #49EB8A;

$blueText: #0F5CD0;

$colors: (
		yellow: '#FBC04E',
		darkBlue: '#0E151D',
		lightBlue: '#1E2731',
		white: '#fff',
		borderColor: '#313942',
		lightBlueBg: '#181F27',
		green: '#0FD05C',
		red: '#D00F0F',
	// grey: '#4A5663',
		grey: '#768696',
		redLight: '#F53D6A',
		greenLight: '#49EB8A',
		blueText: '#0F5CD0',
)