@import "src/assets/styles/variables";

.pointContainer {
	display: flex;
	margin-bottom: 16px;

	.point {
		flex-shrink: 0;
		color: #000;
		background: $yellow;
		border-radius: 50%;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;

		text-align: center;
		letter-spacing: 0.03em;
		height: 24px;
		width: 24px;
		margin-right: 10px;
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			display: block;
		}
	}

	.text {
		white-space: pre-wrap;
		color: $grey;
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 24px;
		}

		span {
			color: $white;
		}
	}
}
