@import "src/assets/styles/variables";

.container {
	height: 100%;
	padding-bottom: 16px;

	@media (min-width: $sm) {
		background: url(../../assets/images/mainPageBg.png) no-repeat bottom center;
	}

	.cardsContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0 auto;
		max-width: 964px;
		gap: 16px;
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 40px;

		@media (min-width: $sm) {
			gap: 32px;
			overflow-y: initial;
			padding-top: 120px;
			flex-direction: row;
			align-items: flex-start;
			overflow-y: auto;
		}

		.cardWrapper {
			max-width: 300px;
			width: 100%;

			.card {
				background-color: $darkBlue;
				position: relative;
				box-sizing: border-box;
				overflow: hidden;
				padding-left: 24px;
				padding-right: 24px;
				border-radius: 20px;
				border: 1px solid $borderColor;
				padding-top: 32px;
				height: 348px;
				width: 300px;

				@media (min-width: $sm) {
					padding-top: 40px;
					height: 400px;
				}

				.cardStep {
					text-align: center;
					font-weight: 500;
					font-size: 12px;
					line-height: 18px;
					color: $grey;
					margin-bottom: 8px;
				}

				.cardHeader {
					text-align: center;
					font-weight: 600;
					margin-bottom: 8px;
					white-space: nowrap;
					font-size: 20px;
					line-height: 30px;
					letter-spacing: -0.035em;

					@media (min-width: $sm) {
						letter-spacing: initial;
						white-space: pre-wrap;
						font-size: 24px;
						line-height: 120%;
					}
				}

				.cardText {
					text-align: center;
					white-space: pre-wrap;
					font-weight: 500;
					font-size: 12px;
					line-height: 18px;
					color: $yellow;
				}

				.cardContent {
					margin-top: 24px;

					@media (min-width: $sm) {
						margin-top: 32px;
					}

					.block {
						border: 1px solid $borderColor;
						border-radius: 10px;
						display: flex;
						align-items: center;
						padding: 12px 16px;

						@media (min-width: $sm) {
							padding: 12px 10px;
						}

						.blockIcon {
							width: 48px;
							height: 48px;
							margin-right: 18px;
						}

						.blockContent {
							.blockTitle {
								font-weight: 600;
								font-size: 18px;
								line-height: 27px;
								color: $yellow;
							}

							.blockText {
								font-weight: 500;
								font-size: 12px;
								line-height: 18px;
							}
						}
					}
				}
			}

			.greyText {
				font-weight: 500;
				font-size: 12px;
				line-height: 18px;
				text-align: center;
				color: $grey;
				white-space: pre-wrap;
				margin-top: 16px;
			}
		}

	}

}