@import "src/assets/styles/variables";

.container {
	padding: 0 20px 16px;

	@media (min-width: $sm) {
		max-width: 844px;
		margin: 0 auto;
	}
}

.text {
	margin-top: 40px;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.03em;
	display: block;
	color: $grey;
	white-space: pre-wrap;

	@media (min-width: $sm) {
		text-align: center;
	}
}