@import "src/assets/styles/variables";

.container {
	.mobileHeader {
		padding: 8px 12px;
		background: $lightBlueBg;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.03em;
		color: $grey;
	}
}

.text {
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: $grey;
	text-align: center;

	@media (min-width: $sm) {
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
	}
}