@import "src/assets/styles/variables";

.container {
	display: flex;
	flex-direction: column;

	.text {
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		color: $grey;
		text-align: center;

		@media (min-width: $sm) {
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
		}
	}

	.smsContainer {
		margin: 0 auto;
		width: 100%;
		max-width: 335px;
		display: grid;
		grid-template-columns: 1fr;
		row-gap: 12px;

		@media (min-width: $sm) {
			width: 100%;
			max-width: 900px;
			column-gap: 20px;
			row-gap: 16px;
			grid-template-columns: 1fr 1fr;
		}
	}

	.paginationContainer {
		width: 335px;
		margin: 16px auto 0;

		@media (min-width: $sm) {
			margin-top: 24px;
			width: 900px;
		}
	}
}