@import "src/assets/styles/variables";

.card {
	cursor: pointer;
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	color: $grey;
	background: $lightBlueBg;
	padding: 16px 4px;
	border-radius: 10px;
	border: 1px solid $lightBlueBg;
	text-align: center;
	white-space: nowrap;
}

.cardActive {
	border-color: $yellow;
	color: $white;
	background: transparent;
}