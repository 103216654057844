@import "src/assets/styles/variables";

.container {
	max-width: 333px;
	margin: 0 auto;

	@media (min-width: $sm) {
		max-width: 900px;
	}

	.desktopTitle {
		display: flex;
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		align-items: center;
		justify-content: center;
		margin-bottom: 24px;

		@media (min-width: $sm) {
			justify-content: flex-start;
		}

		& > span {
			margin-left: 16px;
			display: block;
		}

		.backButton {
			width: 32px;
			height: 32px;

		}
	}

	.infoTitleDesktop {
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: $grey;
	}

	.infoLocation {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;

		@media (min-width: $sm) {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
		}
	}

	.infoPlan {
		margin-top: auto;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: $grey;
		display: inline-block;

		& > div {
			display: inline-block;
			color: $white;
		}
	}

	.infoList {
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: $yellow;
		text-decoration: underline;
		cursor: pointer;
		letter-spacing: 0.03em;
	}

	.infoPrice {
		margin-top: auto;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: $grey;
		display: inline-block;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 24px;
		}

		& > div {
			display: inline-block;
			color: $white;
		}
	}

	.plan {
		border: 1px solid $borderColor;
		border-radius: 10px;
		padding: 12px 20px;
		margin-bottom: 30px;

		& > div:first-child {
			border-bottom: 1px solid $borderColor;
			margin-bottom: 8px;
			padding-bottom: 8px;
		}

		.esimTitle {
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
		}

		.esimPlan {
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;

			& > span {
				color: $grey;
			}
		}

		.infoList {
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			color: $yellow;
			text-decoration: underline;
			cursor: pointer;
		}

		.infoPrice {
			margin-top: auto;
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			color: $grey;
			display: inline-block;

			& > div {
				display: inline-block;
				color: $white;
			}
		}

		.esimUntil {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			color: $grey;

			span {
				display: inline-block;
				margin-left: 4px;
				color: $white;
			}

			& > div:first-child {
				margin-bottom: 2px;
			}
		}
	}

	.greyText {
		color: $grey;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.03em;
		white-space: pre-wrap;

		.white {
			color: $white;
		}
	}

	.smallTitle {
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		white-space: pre-wrap;
	}

	.boxHeader {
		font-weight: 600;
		font-size: 14px;
		line-height: 21px;
		display: flex;
		align-items: flex-start;

		svg {
			margin: 0 8px;
		}

		span {
			color: $yellow;
		}
	}

	.qrCodeContainer {
		display: flex;
	}

	.bottomBadge {
		border: 1px solid $borderColor;
		padding: 6px 12px;
		border-radius: 10px;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.03em;

		.yellow {
			cursor: pointer;
			color: $yellow;
		}
	}

	.row {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		div {
			span {
				color: $grey;
			}
		}
	}
}

.modalContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	outline: 0;
	background: linear-gradient(180deg, $lightBlueBg 0%, $darkBlue 100%);
	padding: 32px 40px 40px;
	border-radius: 20px;
	min-width: 250px;

	@media (min-width: $sm) {
		padding: 40px;
		min-width: 288px;
	}

	.modalTitle {
		margin-bottom: 16px;
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
	}
}