@import "src/assets/styles/variables";

.modalContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	outline: 0;
	background: linear-gradient(180deg, $lightBlueBg 0%, $darkBlue 100%);
	border-radius: 20px;
	min-width: 250px;
	padding: 32px;

	@media (min-width: $sm) {
		padding: 32px 40px 40px;
	}

	.modalTitle {
		white-space: pre-line;
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		text-align: center;

		@media (min-width: $sm) {
			white-space: initial;
			font-size: 24px;
			line-height: 36px;
		}
	}

	@media (min-width: $sm) {
		padding: 40px;
		min-width: 288px;
	}

	.text {
		text-align: center;
		font-size: 14px;
		line-height: 21px;
		white-space: pre-wrap;
	}

	.danger {
		padding: 6px 12px;
		background-color: rgba(208, 15, 15, 0.15);
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.03em;
		border-radius: 5px;
		margin: 16px 0;

		@media (min-width: $sm) {
			width: fit-content;
			margin: 16px auto;
		}

		& span {
			color: $red;
		}
	}
}

