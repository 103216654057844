@import "src/assets/styles/variables";

.button {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;

	&:disabled, &.fakeDisabled {
		opacity: 0.2;
	}
}

.xs {
	padding: 0 12px;
	min-height: 36px;
	border-radius: 10px;
}

.md {
	min-height: 48px;
	border-radius: 20px;

	@media (min-width: $xs) {
		min-height: 60px;
	}
}

.small {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
}

.medium {
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
}

.light {
	color: $darkBlue;
	background: $yellow;
}

.dark {
	color: $white;
	background: $lightBlue;
	border: 1px solid transparent;

	@media (hover: hover) {
		&:hover {
			border-color: $yellow;
		}
	}

	&:active {
		color: $darkBlue;
		background: $yellow;
		border: 1px solid $yellow;
	}
}