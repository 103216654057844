@import "src/assets/styles/variables";

.badge {
	padding: 6px 12px;
	background: rgba(251, 192, 78, 0.15);
	color: $yellow;

	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	border-radius: 10px;
}