@import "../../../../assets/styles/variables";

.container {
	border: 1px solid $borderColor;
	border-radius: 10px;
	padding: 0 32px;

	&.containerActive {
		border-color: $yellow
	}

	.header {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 32px 0;
		box-sizing: border-box;
		flex-direction: row;
		border-bottom: none;

		& > span {
			display: block;
		}

		.headerTitle {
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 2px;
		}

		.headerPlan {
			font-weight: 600;
			font-size: 32px;
			line-height: 32px;
		}

		.headerIcon > svg {
			margin-right: 42px;
			height: 128px;
			width: 128px;
		}

		.headerContent {
			display: flex;
			flex-direction: column;

			.headerContentRow {
				display: flex;

				&:first-child {
					margin-bottom: 20px;
				}

				.footer {
					font-size: 12px;
					line-height: 18px;

					& > div {
						height: 18px;
						white-space: nowrap;
						display: inline-block;
						font-size: 12px;
						line-height: 18px;
						font-weight: 600;
						letter-spacing: 0.03em;
						color: $grey;

						& > strong {
							color: $white;
							margin-left: 8px;
						}

						&:first-of-type {
							margin-bottom: 6px;
						}
					}

					&.footerActive {
						& > div {
							color: $grey;
						}
					}

					.divider {
						display: inline-block;
						font-size: 12px;
						line-height: 18px;
						color: $borderColor;
						margin: 0 8px;
						font-style: normal;
						font-weight: 500;
						letter-spacing: 0.03em;
						opacity: 0.6;
					}
				}
			}
		}
	}

	.priceContainerContent {
		width: 160px;
		margin-right: 48px;
		display: flex;
		flex-direction: column;

		.priceContainerContentTitle {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: $white;
			margin-bottom: 2px;
		}

		.priceContainerContentText {
			font-weight: 600;
			font-size: 24px;
			line-height: 36px;
		}
	}
}