@import "src/assets/styles/variables";

.tabs {
	display: flex;
	margin: 0 auto 24px;
	gap: 6px;
	flex-wrap: wrap;
	justify-content: center;

	@media (min-width: $sm) {
		width: fit-content;
		background: $lightBlueBg;
		border-radius: 10px;
		padding: 8px;
		margin-bottom: 32px;
	}

	.tab {
		position: relative;
		white-space: nowrap;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		text-align: center;
		letter-spacing: 0.03em;
		color: $grey;
		padding: 4px 8px;
		cursor: pointer;
		background: $lightBlueBg;
		border-radius: 5px;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 24px;
			padding: 4px 12px;
		}

		.redDot {
			background: $red;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			position: absolute;
			top: 4px;
			right: 4px;
		}
	}

	.tabActive {
		color: $white;
		background: $borderColor;
	}
}