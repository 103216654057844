@import "src/assets/styles/variables";

.container {
	margin-top: 16px;
	border: 1px solid $borderColor;
	border-radius: 20px;
	padding: 24px;

	@media (min-width: $sm) {
		padding: 24px 40px;
	}

	.title {
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		margin-bottom: 12px;

		@media (min-width: $sm) {
			text-align: left;
			font-size: 18px;
			line-height: 27px;
		}
	}

	.badge {
		display: flex;
		align-items: center;
		background: $lightBlueBg;
		padding: 10px 24px;
		color: $grey;
		border-radius: 10px;
		margin-bottom: 12px;
		font-weight: 600;
		font-size: 14px;
		line-height: 21px;
		border: 1px solid $lightBlueBg;
		cursor: pointer;

		@media (min-width: $sm) {
			margin-bottom: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.badgeActive {
		color: $white;
		border: 1px solid $yellow;
		background: transparent;
	}
}