@import "src/assets/styles/variables";

.text {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	cursor: pointer;
}

.country {
	font-weight: 400;
	font-size: 14px;
	line-height: 190%;
}