@import "../../../../../../assets/styles/variables";

.modalContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	outline: 0;
	background: linear-gradient(180deg, $lightBlueBg 0%, $darkBlue 100%);
	border-radius: 20px;
	min-width: 250px;
	padding: 32px 24px;

	@media (min-width: $sm) {
		padding: 32px 40px 40px;
	}

	.modalTitle {
		white-space: pre-line;
		margin-bottom: 16px;
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		text-align: center;

		@media (min-width: $sm) {
			white-space: initial;
			font-size: 24px;
			line-height: 36px;
		}
	}

	@media (min-width: $sm) {
		padding: 40px;
		min-width: 288px;
	}

	.plan {
		border: 1px solid $borderColor;
		border-radius: 10px;
		padding: 12px 20px;

		& > div:first-child {
			border-bottom: 1px solid $borderColor;
			margin-bottom: 8px;
			padding-bottom: 8px;
		}

		.esimTitle {
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
		}

		.esimPlan {
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;

			& > span {
				color: $grey;
			}
		}

		.esimUntil {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			color: $grey;

			span {
				display: inline-block;
				margin-left: 4px;
				color: $white;
			}

			& > div:first-child {
				margin-bottom: 2px;
			}
		}
	}

	.rowHeader {
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: $grey;
		letter-spacing: 0.03em;
	}

	.row {
		display: flex;
		align-items: flex-start;
		width: 800px;

		.rowText {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;

			span {
				color: $grey;
			}
		}
	}
}

.periodTitle {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	width: fit-content;

	@media (min-width: $sm) {
		font-size: 18px;
		line-height: 27px;
	}
}

.period {
	cursor: pointer;
	font-weight: 600;
	border-radius: 10px;
	border: 1px solid $borderColor;
	padding: 6px 0;
	text-align: center;
	font-size: 14px;
	line-height: 21px;
	width: 48px;
	margin-right: 8px;

	@media (min-width: $sm) {
		margin-right: 16px;
		font-size: 16px;
		line-height: 24px;
		width: 68px;
	}
}

.periodActive {
	border-color: $yellow;
}

.popper {
	z-index: 9999;
}

.notEnoughFundsContainer {
	padding: 8px 12px;
	background: $lightBlueBg;
	max-width: 320px;
	box-sizing: border-box;
	border-radius: 5px;
	margin-bottom: 16px;

	span {
		font-size: 12px;
		line-height: 18px;
		display: block;
		letter-spacing: 0.03em;
		white-space: pre-wrap;
	}

	& .highlighted {
		cursor: pointer;
		color: $yellow;
	}
}