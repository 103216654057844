@import "src/assets/styles/variables";

.container {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.text, .textGrey {
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.03em;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 24px;
		}
	}

	.textGrey {
		color: $grey;
	}

	.selectContainer {
		display: flex;
		align-items: center;
	}

	.select {
		color: $white !important;
		outline: none;
		padding: 0 9px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid $borderColor;
		border-radius: 5px;
		font-size: 14px;
		line-height: 21px;
		margin-right: 16px;
		box-sizing: border-box;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 24px;
			height: 36px;
			padding: 0 12px;
		}

		& > fieldset {
			border: none !important;
		}

		& > div {
			padding: 0;
			padding-right: 4px !important;
		}

		& > svg {
			width: 12px;
			height: 12px;
		}
	}

	.prev {
		margin-right: 8px;
	}

	.rowsPerPage {
		font-size: 12px;
		line-height: 18px;
		color: $grey;
		display: none;
		margin-right: 12px;

		@media (min-width: $sm) {
			display: block;
		}
	}
}