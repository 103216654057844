@import "src/assets/styles/variables";

.container {
	margin: 0 auto;
	max-width: 333px;

	@media (min-width: $sm) {
		max-width: 900px;
	}

	.locationContainer {
		border: 1px solid $borderColor;
		border-radius: 20px;
		padding: 24px;

		@media (min-width: $sm) {
			padding: 24px 40px;
		}

		.locationContainerTitle {
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			margin-bottom: 12px;

			@media (min-width: $sm) {
				text-align: left;
				font-size: 18px;
				line-height: 27px;
			}
		}
	}
}