@import "src/assets/styles/variables";

.button {
	width: 88px;
	height: 62px;
	background: transparent;
	color: $white;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: $red;
	border: 1px solid $red;

	&:disabled {
		opacity: 0.3;
	}

	&:active:not(:disabled) {
		.subtitle {
			color: $white
		}
	}

	& > svg {
		transform: rotate(135deg)
	}
}