@import "src/assets/styles/variables";

.container {
	margin: 0 auto;
	max-width: 333px;

	@media (min-width: $sm) {
		max-width: 1100px;
	}

	.text {
		font-weight: 400;
		font-size: 14px;
		line-height: 21px;
		color: $grey;
		text-align: center;

		@media (min-width: $sm) {
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
		}
	}

	.badge {
		background: $lightBlueBg;
		border-radius: 10px;
		padding: 8px 12px;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: $grey;
	}

	.esim {
		padding: 12px;
		border-bottom: 1px solid $borderColor;

		.esimHeader {
			display: flex;
			justify-content: space-between;
		}

		.esimTitle {
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
		}

		.esimPlan {
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;

			& > span {
				color: $grey;
			}
		}

		.esimUntil {
			display: flex;
			justify-content: space-between;
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			color: $grey;

			span {
				display: inline-block;
				margin-left: 4px;
				color: $white;
			}
		}
	}
}