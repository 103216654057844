@import "src/assets/styles/variables";

.button {
	width: 88px;
	height: 62px;
	background: transparent;
	color: $white;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: $green;
	border: 1px solid $green;

	&:disabled {
		background: transparent;
		color: $borderColor;
		border-color: $borderColor;
		box-sizing: border-box;
	}

	&:active:not(:disabled) {
		.subtitle {
			color: $white
		}
	}
}