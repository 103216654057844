@import "src/assets/styles/variables";

.modal {
	border: 1px solid $borderColor;
	padding: 35px 25px;

	@media (min-width: $sm) {
		width: 440px;
		box-sizing: border-box;
		padding: 40px 35px;
	}
}

.headingYellow {
	color: $yellow;
	font-size: 28px;
	line-height: 34px;
}

.text {
	display: block;
	text-align: center;
	width: 100%;
	white-space: pre-wrap;
	font-size: 20px;
	line-height: 30px;
	@media (min-width: $sm) {
		font-size: 17.5px;
		line-height: 26.25px;
	}
}