@import "src/assets/styles/variables";

.yellowText {
	margin-bottom: 8px;
	text-align: center;
	color: $yellow;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.03em;
}

.toNumber {
	padding: 4px 16px;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	gap: 8px;
	background: $lightBlue;
	border-radius: 5px;
	width: fit-content;
	margin: 0 auto 24px;
	align-items: center;

	svg {
		height: 16px;
	}
}

.greyText {
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: $grey;
}
