@import "src/assets/styles/variables";

.container {
	width: 100%;
	padding-top: 16px;
	margin: 0 auto;
	max-width: 502px;

	@media (min-width: $sm) {
		padding-top: 40px;
		max-width: 690px;
	}

	.header {
		padding-bottom: 16px;
		font-weight: 600;
		font-size: 24px;
		line-height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (min-width: $sm) {
			justify-content: initial;
			padding-bottom: 24px;
		}

		.backButton {
			width: 36px;
			height: 36px;
			margin-right: 12px;

			@media (min-width: $sm) {
				margin-right: 24px;
			}
		}
	}

	.cardsContainer {
		display: flex;
		gap: 8px;
		padding-right: 20px;
		padding-left: 20px;
		margin-bottom: 24px;

		@media (min-width: $sm) {
			gap: 20px;
			padding: 0;
			margin-bottom: 40px;
		}

		.card {
			box-sizing: border-box;
			width: 100%;
			border: 1px solid $borderColor;
			border-radius: 10px;
			display: flex;
			flex-direction: column;
			text-overflow: ellipsis;
			overflow: hidden;
			padding: 8px 12px;

			@media (min-width: $sm) {
				padding: 20px 16px;
			}

			.cardTitle {
				color: $yellow;
				font-weight: 500;
				font-size: 12px;
				line-height: 18px;
				margin: 0 auto;
				margin-bottom: 6px;
			}

			.cardText {
				font-weight: 600;
				font-size: 16px;
				line-height: 24px;
				text-align: center;
				white-space: nowrap;
				width: 100%;

				&.animated {
					animation: marquee 5s infinite;
					@keyframes marquee {
						0% {
							transform: translate(0, 0);
						}
						50% {
							transform: translate(-100%, 0);
						}
						100% {
							transform: translate(0, 0);
						}
					}
				}

				@media (min-width: $sm) {
					font-size: 18px;
					line-height: 27px;
				}

				div > svg {
					width: 20px;
					height: 20px;
					margin-right: 8px;

					@media (min-width: $sm) {
						width: 24px;
						height: 24px;
					}
				}

			}
		}

	}

	.addressContainer {
		display: flex;
		flex-direction: column;
		align-items: center;

		@media (min-width: $sm) {
			flex-direction: row;
		}

		.qrCodeContainer {
			width: 160px;
			height: 160px;
			box-sizing: border-box;
			background: $white;
			padding: 10px;
			margin-bottom: 24px;

			@media (min-width: $sm) {
				padding: 20px;
				margin-bottom: 0;
				margin-right: 40px;
				width: 210px;
				height: 210px;
			}
		}

		.addressContent {
			display: flex;
			flex-direction: column;
			width: 100%;
			padding: 0 20px;
			box-sizing: border-box;

			@media (min-width: $sm) {
				padding: 0;
				width: calc(100% - 250px);
			}

			.greySmallText {
				font-weight: 500;
				font-size: 12px;
				line-height: 18px;
				color: $grey;
			}

			.label {
				color: $yellow;
				font-weight: 500;
				font-size: 12px;
				line-height: 18px;
				display: block;
				margin-bottom: 4px;
			}

			.field {
				border-radius: 10px;
				padding: 6px 12px;
				border: 1px solid $borderColor;
				display: flex;
				align-items: center;
				cursor: pointer;
				height: 24px;

				.text {
					font-weight: 600;
					font-size: 14px;
					line-height: 21px;
					white-space: nowrap;
				}

				.copyIcon {
					width: 13px;
					flex-shrink: 0;
					margin-left: auto;
					padding-left: 8px;
					cursor: pointer;

					&.active {
						path {
							fill: $yellow;
						}
					}

					@media (hover: hover) {
						&:hover {
							path {
								fill: $white;
							}
						}
					}
				}

				svg {
					justify-self: flex-end;
				}
			}
		}
	}

}

.copiedContainer {
	display: flex;
	background: $lightBlueBg;
	border-radius: 5px;
	height: 19px;
	box-sizing: border-box;
	align-items: center;
	justify-content: center;
	padding: 2px 6px;
	margin-top: 4px;

	.copied {
		display: block;
		font-weight: 600;
		font-size: 10px;
		line-height: 15px;
	}
}
