@import "src/assets/styles/variables";

.container {
	padding: 12px;
	border-bottom: 1px solid $borderColor;

	.tag {
		padding: 2px 4px;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.03em;
		display: flex;
		align-items: center;
		border: 1px solid $borderColor;
		border-radius: 5px;
		color: $grey;

		.tagDot {
			margin-left: 3px;
			margin-right: 5px;
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: $red;
		}

		.tagDotActive {
			background-color: $green;
		}
	}

	.text {
		display: flex;
		align-items: center;
		margin-right: 12px;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.03em;
		white-space: nowrap;

		.grey {
			color: $grey;
		}
	}
}