@import "src/assets/styles/variables";

.modalContainer {
	outline: 0;
	border: 1px solid $borderColor;
	background: $lightBlueBg;
	border-radius: 20px;
	min-width: 250px;
	padding: 16px 20px;
	position: relative;
	margin: 16px auto 0;
	width: fit-content;
	max-width: calc(100% - 32px);
	box-sizing: border-box;

	@media (min-width: $sm) {
		min-width: 300px;
		max-width: 400px;
		padding: 16px 24px;
		margin-left: auto;
		margin-right: 16px;
		margin-top: 78px;
	}

	.modalTitle {
		white-space: pre-line;
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		display: flex;
		align-items: center;
		padding-right: 24px;

		@media (min-width: $sm) {
			display: block;
			white-space: initial;
			font-size: 24px;
			line-height: 36px;
			padding-right: initial;
		}

		.smsTitleText {
			color: $yellow;
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
			letter-spacing: 0.03em;
			margin-left: 8px;
		}
	}

	.text {
		font-size: 14px;
		line-height: 21px;
		white-space: pre-wrap;
		max-height: 200px;
		overflow-y: auto;
		margin-top: 8px;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 24px;
			margin-top: 12px;
		}
	}

	.closeButtonContainer {
		width: 32px;
		height: 32px;

		.closeButton {
			top: 16px;
			right: 16px;
		}
	}
}

