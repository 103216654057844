@import "src/assets/styles/variables";

.cardHeaderNumbers {
	display: flex;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.03em;
	align-items: center;

	svg {
		margin: 0 4px;
	}

	.cardHeaderFrom {
		border: 1px solid $borderColor;
		border-radius: 5px;
		padding: 4px 8px;

		@media (min-width: $sm) {
			padding: 6px 12px;
			border-radius: 10px;
		}
	}

	.cardHeaderTo {
		border: 1px solid $yellow;
		border-radius: 5px;
		padding: 4px 8px;

		@media (min-width: $sm) {
			padding: 6px 12px;
			border-radius: 10px;
		}
	}
}