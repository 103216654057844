@import "src/assets/styles/variables";

.tableRow {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	padding: 12px 24px;
	//height: 48px;
	align-items: center;
	border-bottom: 1px solid $borderColor;

	.smallText {
		font-weight: 400;
		font-size: 14px;
		line-height: 14px;
		letter-spacing: 0.03em;
	}

	.greyText {
		color: $grey;
	}

	.dot {
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background: $red;

		&.dotActive {
			background: $green;
		}
	}
}