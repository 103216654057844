@import "src/assets/styles/variables";

.container {
	max-width: 400px;
	margin: 0 auto;

	@media (min-width: $sm) {
		max-width: initial;
	}

	.title {
		display: flex;
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		align-items: center;
		justify-content: center;
		gap: 12px;
		margin-bottom: 24px;

		@media (min-width: $sm) {
			justify-content: flex-start;
			font-size: 24px;
			line-height: 36px;
		}

		svg {
			height: 32px;
			width: 32px;
		}
	}

	.mobileSubtitle {
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.03em;
	}

	.infoTitleDesktop {
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: $grey;
	}

	.infoList {
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: $yellow;
		text-decoration: underline;
		cursor: pointer;
		letter-spacing: 0.03em;

		@media (min-width: $sm) {
			margin-left: 16px;
		}
	}

	.infoPrice {
		margin-top: auto;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: $grey;
		display: inline-block;

		@media (min-width: $sm) {
			font-size: 16px;
			line-height: 24px;
		}

		& > div {
			display: inline-block;
			color: $white;
		}
	}
}

.notEnoughFundsContainer {
	padding: 8px 12px;
	background: $lightBlueBg;
	max-width: 320px;
	box-sizing: border-box;
	border-radius: 5px;
	margin-bottom: 16px;

	span {
		font-size: 12px;
		line-height: 18px;
		display: block;
		letter-spacing: 0.03em;
		white-space: pre-wrap;
	}

	& .highlighted {
		cursor: pointer;
		color: $yellow;
	}
}

.modalTitle {
	margin-bottom: 16px;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
}

.country {
	font-weight: 400;
	font-size: 14px;
	line-height: 190%;
}

.modalContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	outline: 0;
	background: linear-gradient(180deg, $lightBlueBg 0%, $darkBlue 100%);
	padding: 32px 40px 40px;
	border-radius: 20px;
	min-width: 250px;

	@media (min-width: $sm) {
		padding: 40px;
		min-width: 288px;
	}
}

.periodTitle {
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	width: fit-content;
}

.period {
	cursor: pointer;
	font-weight: 600;
	border-radius: 10px;
	border: 1px solid $borderColor;
	padding: 6px 0;
	text-align: center;
	font-size: 14px;
	line-height: 21px;
	width: 60px;
	margin-right: 8px;

	@media (min-width: $sm) {
		margin-right: 16px;
		font-size: 16px;
		line-height: 24px;
		width: 68px;
	}
}

.periodActive {
	border-color: $yellow;
}

.total {
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	color: $yellow;
	margin: 24px 0;
	text-align: center;

	@media (min-width: $sm) {
		margin: 32px 0;
		text-align: left;
	}

	span {
		margin-left: 12px;
		color: $white;
	}
}

.row {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.yellow {
	color: $yellow;
}